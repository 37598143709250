import React from 'react'
import Layout from '../components/Layout'
import SEO from "../components/seo"
import BookingForm from "../components/BookingForm"
import PageBanner from '../components/PageBanner'
import banner_image from '../images/page-banners/404.jpg'

const NotFoundPage = () => (
  <Layout sidebarComponent={<BookingForm colWidth="col-md-12" />}>
      
    <SEO title="404 - Not found page" />

    <PageBanner image={banner_image} title="404 - Not found page"/>

    <div className="inner-content">
      
    </div>

  </Layout>
) 

export default NotFoundPage 
